
import { Component, Vue } from "vue-property-decorator";
import SendTicketForm from "@/components/safeHouse/SendTicketForm.vue";
import FormCard from "@/components/FormCard.vue";

@Component({
  components: {
    SendTicketForm,
    FormCard
  }
})
export default class SendRequest extends Vue {
  private mounted() {
    window.scrollTo(0, 0);
  }
}
